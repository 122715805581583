import React, {ChangeEvent, useEffect, useState} from "react";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {IOrder} from "../types/Order";
import DataTable from "../components/DataTable/DataTable";

import {Navigate, NavLink, useNavigate} from "react-router-dom";

import CSelect from "../components/Cselect/CSelect";

import Checkbox from "../components/Checkbox/Checkbox";
import {ICity} from "../types/City";
import Input from "../components/Input/Input";
import {IPartnerState} from "../types/PartnerState";
import DtFormatter from "../helpers/DtFormatter";

export default function MyOrders() {
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [partnersStates, setPartnerStates] = useState(Array<IPartnerState>);

    const [isOnlyAlive, setIsOnlyAlive] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState("-");
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("-");
    const [selectedDate, setSelectedDate] = useState("");

    const [error, setError] = useState(false);
    useEffect(() => {
        axios.get(API + '/cities/').then((response) => {
            if (response?.data) {
                setCities(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/PartnersStates/').then((response) => {
            if (response?.data) {
                setPartnerStates(response.data.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        }).finally(() => {
            axios.get(API + '/orders/').then((response) => {
                if (response?.data) {
                    setOrders(response.data);
                    // console.log(response.data)
                }
            }).catch((e) => {
                setError(true);
            });
        })
    }, [])


    useEffect(() => {
        axios.post(API + '/orders/', {
            isOnlyAlive: isOnlyAlive,
            cityId: selectedCity,
            order_partner_state_id: selectedStatus,
            date: selectedDate
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setOrders(response.data);
        }).catch((e) => {
            setError(true);
        });
    }, [isOnlyAlive, selectedCity, selectedStatus, selectedDate])

    const navigate = useNavigate();
    return (<>
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Orders</div>
                <div className={st.body_panel_heading_control}>
                    <div className={st.gap8} style={{width: 'max-content', minWidth: 180 + "px", display: 'flex'}}>
                        <Checkbox name={'AliveOnly'} value={1} isChecked={isOnlyAlive}
                                  onChange={() => setIsOnlyAlive(!isOnlyAlive)}/> - Only Alive
                    </div>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedStatus} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedStatus(obj?.target?.value);
                        }} name={'partnersStates'} isSelect2={true} values={partnersStates as any} placeHolder={'State'}/>
                    </div>
                    {/*<div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>*/}
                    {/*    <CSelect value={selectedCity} onChange={(obj: ChangeEvent<HTMLInputElement>) => {*/}
                    {/*        setSelectedCity(obj?.target?.value);*/}
                    {/*    }} name={'orderState'} isSelect2={true} values={cities as any} placeHolder={'City'}/>*/}
                    {/*</div>*/}
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <Input type={"date"} value={selectedDate} onChange={(obj) => {
                            setSelectedDate(obj.target.value)
                        }} name={'date'} placeholder={'Date'}/>
                    </div>
                    {/*<Input name={'search'} value={searchText} onChange={(evt) => setSearchText(evt.target.value)}*/}
                    {/*       placeholder={'type contact'}/>*/}
                    {/*<NavLink to={'/contactForm/'}><Button color={"blue"} type={"plus"}>Add*/}
                    {/*    contact</Button></NavLink>*/}
                </div>
            </div>
            <div className={st.body_panel_body}>

            </div>

            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'Create Moment',
                        id: 'create_moment_',
                        render: (row: any) => {
                            return DtFormatter(row.ts_partner_request);
                        }
                    },
                    {
                        name: 'State',
                        id: 'status_',
                        render: (row: any) => {
                            let r = 'unknown';
                            partnersStates.forEach((i) => {
                                if (i.id == row.order_partner_state_id) {
                                    r = i.name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'Deadline',
                        id: 'dead_line_',
                        render: (row: any) => {
                            return DtFormatter(row.partner_ts_deadline);
                        }
                    },
                    {
                        name: 'Date From',
                        id: 'date_from_',
                        render: (row: any) => {
                            return DtFormatter(row.ts_from);
                        }
                    },
                    {
                        name: 'Duration',
                        id: 'duration_',
                        field: 'duration'
                    },
                    {
                        name: 'City',
                        id: 'city_id_',
                        render: (i: any) => {
                            let city = "-";
                            cities.map((c: ICity) => {
                                if (c.id == i.city_id) {
                                    city = c.name;
                                }
                            })
                            return city;
                        }
                    },
                    {
                        name: 'Suitable Cars',
                        id: 'partner_vehicle_count_',
                        field: 'partner_vehicle_count'
                    },
                    {
                        name: 'Pickup & Drop off Location',
                        id: 'p_s_d_o_l_',
                        render: (row: any) => {
                            return (<>{row.pickup_location}<br/>{row.drop_location}</>);
                        }
                    },


                ]}
                onRowDoubleClick={(row) => {
                    let link = '/myOrderShow/' + row.uid_partner_request + '/';
                    navigate(link, {replace: true});
                }}
                rowStyle={(row) => {
                    return row.is_deadline ? st.bgLightRed : undefined
                }}
                rowsPerPage={50}
                data={orders}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/>
        </>
    )

}