import {Navigate} from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({children}: any) => {

    const user = localStorage.getItem("user");
    const backLink = sessionStorage.getItem('backLink');
    if (!backLink) {
        sessionStorage.setItem('backLink', window.location.href);
    }
    if (!user) {
        // user is not authenticated
        return <Navigate to="/login"/>;
    }
    return children;
};