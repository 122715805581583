import st from "../styles/template.module.scss";
import React, {memo, useEffect} from "react";
import Input from "../components/Input/Input";
import Checkbox from "../components/Checkbox/Checkbox";
import Textarea from "../components/Textarea/Textarea";
import {useImmer} from "use-immer";
import axios from "axios";
import {optionType} from "../components/Select/types/optionTypes";
import RadioButton from "../components/RadioButton/RadioButton";
import Button from "../components/Button/Button";
import PriceOptionsLine from "./parts/PriceOptionsLine";
import Gallery from "../components/Gallery/Gallery";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../const/API";
import CSelect from "../components/Cselect/CSelect";

let initialData = {
    id: 0,
    model_id: '',
    extra_txt: '',
    category_id: '',
    class_id: '',
    body_color_id: '-',
    interior_color_id: '-',
    seats: '',
    manufactured: '',
    drive_type_id: '',
    engine: '',
    engine_hp: '',
    top_speed: '',
    transmission_id: '',
    transmission: '',
    fuel_type_id: '',
    fuel_consumption: '',
    counterparty_id: '',
    city_id: '',
    country_id: '',
    cp_art_number: '',
    plate_number: '',
    driver_age: '',
    published: 'false',
    image_id: '',
    image_list: '',
    video_link: '',
    extra_options: '',
    currency_id: '',
    price_options: '',
    description: '',
    to100_time: '',
    wheel_size: '',
    brand_id: '3',
    min_rent_period: '',
    min_drive_exp: '',
    checkedExtraOption: [],
    imgs: [],
    mainImg: '',
    daily_mileage: '',
    partner_link: '',
};
type counterPartyPriceType = {
    days: string,
    caption: string
};

type PriceType = { days: string, value: string };
let userPrices: Array<counterPartyPriceType> = [];
let userMillage: string;

let Prices: Array<PriceType> = [];
//получаем ExtraOptions
let extraOptions: any;
let brands: any;
let models: any;
let categories: any;
let classes: any;
let fuelTypes: any;
let transmissions: any;
let driveTypes: any;
let colors: any;
let priceOptions: any = {};
let counterparties: any;
let cities: any;
let countries: any;

let collectData = async () => {

    //если он получены то ничего получать не надо !


    if (localStorage.getItem('user') === '101') {
        axios.get(API + '/counterparties/').then((response) => counterparties = response.data).catch((e) => console.log(e));
        axios.get(API + '/cities/').then((response) => cities = response.data).catch((e) => console.log(e));
        axios.get(API + '/countries/').then((response) => countries = response.data).catch((e) => console.log(e));
    }
    if (localStorage.getItem('user') === '202') {
        axios.get(API + '/prices/').then((response) => {
            userPrices = JSON.parse(response.data.price_pattern);
            userMillage = response.data.def_daily_mileage;
            document.title = 'Car form - ' + response.data.name;
            // console.log({response});
        }).catch((e) => console.log(e));
    }
    if (brands?.length > 0) {
        return true;
    }
    return Promise.all([
        axios.get(API + '/ExtraOptions/').then((response) => extraOptions = response.data).catch((e) => console.log(e)),
//получаем бренды для первого поля
        axios.get(API + '/brands/').then((response) => brands = response.data).catch((e) => console.log(e)),
//получаем модели
        axios.get(API + '/models/').then((response) => models = response.data).catch((e) => console.log(e)),
//Получаем категории
        axios.get(API + '/categories/').then((response) => categories = response.data).catch((e) => console.log(e)),
//получаем классы
        axios.get(API + '/classes/').then((response) => classes = response.data).catch((e) => console.log(e)),
//получаем тип топлива
        axios.get(API + '/fueltypes/').then((response) => fuelTypes = response.data).catch((e) => console.log(e)),
//получаем трансмиссии
        axios.get(API + '/Transmissions/').then((response) => transmissions = response.data).catch((e) => console.log(e)),
//получаем типы приводов
        axios.get(API + '/DriveTypes/').then((response) => driveTypes = response.data).catch((e) => console.log(e)),
//получаем цвета
        axios.get(API + '/colors/').then((response) => colors = response.data).catch((e) => console.log(e)),
//получаем Extras
        axios.get(API + '/priceoptions/').then((response) => priceOptions = response.data).catch((e) => console.log(e))]);
}

//поля обязательные для заполнения
let validate = [
    {name: 'class_id'},
    {name: 'category_id'},
    {name: 'model_id'},
    {name: 'fuel_type_id'},
    {name: 'transmission_id'},
    {name: 'drive_type_id'},
    {name: 'driver_age'},
    {name: 'min_drive_exp'},
    {name: 'manufactured'},
    {name: 'seats'},
    {name: 'body_color_id'},
    {name: 'interior_color_id'}
];


function AutoForm() {

    const navigate = useNavigate();
    let {carId} = useParams();
    const [isReady, setReady] = useImmer(false);
    const [checkedImages, setCheckedImages] = useImmer([]);
// console.log(counterparties);
    const filteredCities = () => {
        if (cities) {
            let counterparty = counterparties.filter((c: any) => c.id + '' === data.counterparty_id + '')[0];
            let country = countries.filter((c: any) => c.id + '' === data.country_id + '')[0];
            if (counterparty || country) {
                let res = cities;
                if (counterparty) {
                    res = cities.filter((c: any) => c.id + '' === counterparty.city_id + '')
                }
                if (country) {
                    res = res.filter((c: any) => c.country_id + '' === country.id + '')
                }
                return res;
            } else {
                return cities;
            }
        } else {
            return [];
        }
    }
    const filteredCountries = () => {
        let city = cities.filter((c: any) => c.id + '' === data.city_id + '')[0];
        if (city) {
            return countries.filter((country: any) => country.id + '' === city.country_id + '');
        } else {
            return countries;
        }
    }

    const onSortOfGallery = async (img: any) => {
        let canSort = true;
        img.forEach((item: string) => {
            if (item.indexOf('blob') >= 0) {
                canSort = false;
            }
        })

        if (!canSort || data.id < 1) {
            alert('Save before you sort');
        } else {
            await axios.post(API + '/SetSort/' + data.id + "/", {
                img: img,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log({resp});
        }

    }


    useEffect(() => {
        collectData().then(() => {
            // console.log(brands);
            if (carId !== undefined) {
                axios.get(API + '/vehicle/' + carId + '/').then((response) => {
                    setData((draft) => {
                        Object.entries(response.data).forEach((item: Array<any>) => {
                            if (typeof (draft as any)[item[0]] !== "undefined") {
                                (draft as any)[item[0]] = item[1];
                            }
                        });
                        if (typeof response.data.driver_age_effective !== "undefined") {
                            (draft as any).driver_age = response.data.driver_age_effective;
                        }
                        if (typeof response.data.extra_options !== "undefined") {
                            let newExtraOptions = [...draft.checkedExtraOption];
                            let all = [...newExtraOptions, ...JSON.parse(response.data.extra_options)];
                            (draft as any).checkedExtraOption = Array.from(new Set(all));
                        }
                        let counterPartyId = parseInt(draft.counterparty_id);
                        if (!isNaN(counterPartyId) && counterPartyId > 0 && localStorage.getItem("user") === '101') {
                            let counterparty = counterparties.filter((cp: any) => cp.id === counterPartyId)[0];
                            let city = cities.filter((ci: any) => ci.id === counterparty.city_id)[0];
                            if (city && counterparty) {
                                draft.counterparty_id = counterparty.id;
                                draft.city_id = city.id;
                                draft.country_id = city.country_id;
                            }

                        }

                        return draft;
                    });
                    const timePrice = JSON.parse(response.data.price_options);
                    if (timePrice.price !== undefined) {
                        if (userPrices.length > 0) {
                            setPrices((draft => {
                                let arr: Array<PriceType> = [];
                                userPrices.forEach((price) => {
                                    let line: PriceType = {days: "", value: ""};
                                    line.days = price.days;
                                    timePrice.price.forEach((priceItem: PriceType) => {
                                        if (priceItem.days == line.days) {
                                            line.value = priceItem.value;
                                        }
                                    })
                                    arr.push(line);
                                })
                                return arr;
                                // console.log("DRAFT");
                            }))
                        } else {
                            setPrices(timePrice.price);
                        }
                    }
                    let forExtras = {...timePrice};
                    if (typeof forExtras.price !== "undefined") {
                        delete forExtras.price;
                    }

                    //устанавливаем поля Extras
                    setExtras(forExtras);
                    setReady(true);
                }).catch((e) => {
                    setReady(false);
                });
            } else {
                if (userPrices.length > 0) {
                    setPrices((draft => {
                        let arr: Array<PriceType> = [];
                        userPrices.forEach((price) => {
                            let line: PriceType = {days: "", value: ""};
                            line.days = price.days;
                            arr.push(line);
                        })
                        return arr;
                    }))

                }
                setReady(true);
            }

        });

    }, []);
    // console.log({isReady})

    const [data, setData] = useImmer(initialData);
    const [counterPartySymbol, setCounterPartySymbol] = useImmer(null);
    const [prices, setPrices] = useImmer(Prices);
    const [extras, setExtras] = useImmer({});
    const [errors, setErrors] = useImmer(Array<string>);
    const [newImages, setImages] = useImmer<FileList[]>([]);

    const validateFields = () => {
        setErrors(draftOfErrors => {
            validate.forEach((field) => {
                if ((data as any)[field.name] !== null) {
                    if ((data as any)[field.name].toString().trim().length === 0) {
                        if (draftOfErrors.indexOf(field.name) === -1) {
                            draftOfErrors.push(field.name);
                        }
                    } else {
                        if (draftOfErrors.includes(field.name)) {
                            if (draftOfErrors[draftOfErrors.indexOf(field.name)] !== "undefined") {
                                draftOfErrors.splice(draftOfErrors.indexOf(field.name), 1);
                            }
                        }
                    }
                }
            })
            let seats = parseInt(data.seats);
            if (seats < 1 || seats > 21 || isNaN(seats)) {
                if (!draftOfErrors.includes('seats')) {
                    draftOfErrors.push('seats')
                }
            }
            let manufactured = parseInt(data.manufactured);
            if (manufactured < 1901 || manufactured > 2100 || isNaN(manufactured)) {
                if (!draftOfErrors.includes('manufactured')) {
                    draftOfErrors.push('manufactured')
                }
            }
            let body_color_id = parseInt(data.body_color_id);

            if (isNaN(body_color_id)) {
                if (!draftOfErrors.includes('body_color_id')) {
                    draftOfErrors.push('body_color_id')
                }
            }

            let interior_color_id = parseInt(data.interior_color_id);
            if (isNaN(interior_color_id)) {
                if (!draftOfErrors.includes('interior_color_id')) {
                    draftOfErrors.push('interior_color_id')
                }
            }

            let driver_age = parseInt(data.driver_age);

            if ((driver_age > 0 && (driver_age < 16 || driver_age > 40)) || isNaN(driver_age)) {
                if (!draftOfErrors.includes('driver_age')) {
                    draftOfErrors.push('driver_age')
                }
            } else {
                if (draftOfErrors.includes('driver_age')) {
                    draftOfErrors.splice(draftOfErrors.indexOf('driver_age'), 1);
                }
            }


            return draftOfErrors;
        });
    };

    const onPriceOptionsLineChange = (name: string, value: string) => {
        setExtras((draft) => {
            if (value !== '---') { //признак для удаления записи при снятии галочки, в этот момент сюда пишется ---
                (draft as any)[name] = value
            } else {
                delete (draft as any)[name];
            }
        })
    }

    const changePrice = (obj: HTMLInputElement, index: number): void => {
        setPrices(draft => {
            (draft as any)[index][obj.name] = obj.value;
        })
    }

    const changeHandler = (obj: Event): void => {
        const objTarget: any = obj.target;
        setData(draft => {
            (draft as any)[objTarget.name] = objTarget.value
            //смена модели

            if (objTarget.name === "model_id") {
                if (objTarget.value + "" !== '-') {
                    let model = models.filter((m: any) => m.id + "" === objTarget.value + "")[0];
                    draft.class_id = model.class_id;
                    draft.category_id = model.category_id;
                    draft.drive_type_id = model.drive_type_id;
                    draft.fuel_type_id = model.fuel_type_id;
                    draft.transmission_id = model.transmission_id;
                    draft.video_link = model.video_link;
                } else {
                    draft.class_id = '-';
                    draft.category_id = '-';
                    draft.drive_type_id = '-';
                    draft.fuel_type_id = '-';
                    draft.transmission_id = '-';
                    draft.video_link = '';
                }
            }
            if (objTarget.name === "counterparty_id") {
                let counterParty = counterparties.filter((c: any) => c.id + '' === objTarget.value + '')[0];
                // console.log(counterParty, counterParty.price_pattern);

                if (counterParty) {
                    axios.get(API + '/CurrencySymbol/'+counterParty.def_currency_id+'/').then((resp) => {
                        setCounterPartySymbol(resp.data.symbol);
                    });
                    if (typeof counterParty.price_pattern == "string") {
                        counterParty.price_pattern = JSON.parse(counterParty.price_pattern);
                    }
                    //устанавливаем периоды которые нужны для данного контрагента
                    setPrices((draft) => {
                        let arr: any = [];
                        counterParty.price_pattern.forEach((item: counterPartyPriceType, key: any) => {
                            let obj = {days: '', value: ''};
                            obj.days = item.days;
                            obj.value = typeof draft[key]?.value !== "undefined" ? draft[key]?.value : "";
                            arr.push(obj);
                        })
                        return arr;

                    })
                    let city = cities.filter((c: any) => c.id + '' === counterParty.city_id + '')[0];
                    draft.city_id = counterParty.city_id;
                    if (city) {
                        draft.country_id = city.country_id;
                    }
                    // console.log(draft.daily_mileage, counterParty.def_daily_mileage);
                    let num = parseFloat(draft.daily_mileage);
                    if ((isNaN(num) || num < 1) && counterParty.def_daily_mileage) {
                        draft.daily_mileage = counterParty.def_daily_mileage;
                    }
                } else {
                    setPrices([]);
                }
            }
            if (objTarget.name === "city_id") {
                let city = cities.filter((c: any) => c.id + '' === objTarget.value + '')[0];
                if (city) {
                    draft.country_id = city.country_id;
                }
            }

        });

    };

    const hasPriceError = (item: any, field: string) => {
        // console.log(item, field);
        if (item.days === 1 && item.value.length === 0 && field !== 'days') {
            if (item[field] == null) {
                return true;
            }
            return item[field].length === 0;
        }
        if (item.days.length > 0 || item.value.length > 0) {
            if (item[field] == null) {
                return true;
            }
            return item[field].length === 0;
        }

    }

    const modelFiltered = (brandId: any) => {
        // console.log({brandId});
        let result;
        if (Array.isArray(models)) {
            result = (models as any).filter((model: any) => brandId + "" === model.brand_id + "");
        } else {
            result = [];
        }
        // console.log(result);
        return result;
    }


    const changeExtraOptionsHandler = (obj: Event): void => {

        const objTarget = obj.target as HTMLInputElement;

        let newExtraOptions = JSON.parse(JSON.stringify(data.checkedExtraOption));

        if (objTarget.checked) {
            newExtraOptions.push(objTarget.name);
        } else {
            newExtraOptions.splice(newExtraOptions.indexOf(objTarget.name), 1);
        }
        setData({...data, checkedExtraOption: newExtraOptions});
    };

    useEffect(() => {
        validateFields();
    }, [data]);
    // console.log("RUN");
    const sendDataButton = async () => {
        validateFields();
        let PriceHasError = false;
        //проверим на ошибки цены, ошибки нет, если все поля или заполнены или пустые на всей строке
        prices.forEach((item, index) => {
            if (!((parseFloat(item.days) > 0 && item.value) || (parseFloat(item.days) < 1 && !item.value))) {
                PriceHasError = true;
                console.log("prices error", item);
            }
        });
        if (PriceHasError) {
            alert('check prices fields');
        }
        // Ошибки в строке extras
        let ExtrasHasErrors = false;
        Object.entries(extras).forEach(([key, value]) => {
            if (value === '') {
                ExtrasHasErrors = true;
            }
        });
        const finalNoError = (errors.length === 0) && !PriceHasError && !ExtrasHasErrors;
        //если ошибок нет, начинаем работу с изображениями.
        if (finalNoError) {
            let resp = await axios.post(API + '/savevehicle/', {
                obj: data,
                prices: prices,
                extras: extras,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const d = JSON.parse(resp.data.data);
            //Если это только создана авто, то у нее вернется ID, иначе уже был
            // {"status" : 0, "message" : "Vehicle created successfully", "id" : 877}
            if (d.status === 0) {
                if (newImages.length === 0) {
                    navigate('/cars');
                } else {
                    //Получем ID для отправки картинок
                    let id = (d.id) ? d.id : data.id;
                    //посылаем картинки
                    let resp = await axios.post(API + '/UploadImgs/' + id + "/", {
                        img: newImages,
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    //если все хорошо, то мы завершаем работу.
                    if (resp.status !== 200) {
                        alert('some error');
                    } else {
                        setData({...data, imgs: resp.data});
                        navigate('/cars');
                    }
                }
            } else {
                //если ошибка, говорим об этом.
                alert(d.message);
            }

        } else {
            alert('Errors in form');
            console.log("ERROR!!! CAN'T START SAVE");
        }
    }
    const removeImages = async () => {
        let resp = await axios.post(API + '/DelImgs/', {
            id: data.id,
            paths: checkedImages
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (resp.status !== 200) {
            alert('some error');
        } else {
            setData({...data, imgs: resp.data.data});
        }


    }
    const setMainPAge = async () => {
        let resp = await axios.post(API + '/SetMainPage/', {
            id: data.id,
            path: checkedImages[0]
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (resp.status !== 200) {
            alert('some error');
        } else {
            setData({...data, mainImg: checkedImages[0]});
        }


    }

    // console.log(userMillage, data);
    return (
        (!isReady) ? (<>one moment please</>) : (<>
            <div className={st.body_panel_heading}>
                <div className={'f24'}>{data.id > 0 ? 'Edit' : 'Add'} car</div>
            </div>
            <div className={st.body_panel_body}>
                <div className={st.form}>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Brand*</label>
                            <CSelect values={brands as optionType} name={'brand_id'} value={data.brand_id}
                                     onChange={changeHandler} isSelect2={true} hasError={errors.includes('brand_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Model*</label>
                            <CSelect values={modelFiltered(data.brand_id)} name={'model_id'}
                                     value={data.model_id}
                                     onChange={changeHandler} hasError={errors.includes('model_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Extra description</label>
                            <Input name={'extra_txt'} value={data.extra_txt}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    {(localStorage.getItem("user") === '101' && (
                        <div className={st.row}>
                            <div className={st.formVertical}>
                                <label>Counterparty</label>
                                <CSelect values={counterparties} name={'counterparty_id'}
                                         value={data.counterparty_id}
                                         onChange={changeHandler} hasError={errors.includes('counterparty_id')}/>
                            </div>
                            <div className={st.formVertical}>
                                <label>Country*</label>
                                <CSelect values={filteredCountries()} name={'country_id'}
                                         value={data.country_id}
                                         onChange={changeHandler} hasError={errors.includes('country_id')}/>
                            </div>
                            <div className={st.formVertical}>
                                <label>City*</label>
                                <CSelect values={filteredCities()} name={'city_id'}
                                         value={data.city_id}
                                         onChange={changeHandler} hasError={errors.includes('city_id')}/>
                            </div>
                        </div>
                    ))}
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Car category*</label>
                            <CSelect values={categories as optionType} name={'category_id'}
                                     value={data.category_id}
                                     onChange={changeHandler} hasError={errors.includes('category_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Car class*</label>
                            <CSelect values={classes as optionType} name={'class_id'} value={data.class_id}
                                     onChange={changeHandler} hasError={errors.includes('class_id')}/>
                        </div>
                        <div className={st.formVertical}>

                            <label>Fuel type*</label>
                            <CSelect values={fuelTypes as optionType} name={'fuel_type_id'}
                                     value={data.fuel_type_id}
                                     onChange={changeHandler} hasError={errors.includes('fuel_type_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Fuel consumption</label>
                            <Input name={'fuel_consumption'}
                                   value={(data.fuel_consumption === null ? '' : data.fuel_consumption)}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Transmission type*</label>
                            <CSelect values={transmissions as optionType} name={'transmission_id'}
                                     value={data.transmission_id}
                                     onChange={changeHandler} hasError={errors.includes('transmission_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Drive type*</label>
                            <CSelect values={driveTypes as optionType} name={'drive_type_id'}
                                     value={data.drive_type_id}
                                     onChange={changeHandler} hasError={errors.includes('drive_type_id')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Transmission</label>
                            <Input name={'transmission'} value={data.transmission}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Wheel radius</label>
                            <Input name={'wheel_size'} value={data.wheel_size}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Min rent period (days)</label>
                            <Input name={'min_rent_period'} value={data.min_rent_period}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Minimum age of the driver*</label>
                            <Input name={'driver_age'} errorText={'from 16 to 40'} value={data.driver_age}
                                   onChange={changeHandler} hasError={errors.includes('driver_age')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Driver`s Min Experience, years*</label>
                            <Input name={'min_drive_exp'} value={data.min_drive_exp}
                                   onChange={changeHandler} hasError={errors.includes('min_drive_exp')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Plate number</label>
                            <Input name={'plate_number'} value={data.plate_number}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Engine</label>
                            <Input name={'engine'} value={data.engine}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Manufactured (year)*</label>
                            <Input name={'manufactured'} value={data.manufactured}
                                   onChange={changeHandler} errorText={'from 1901 to 2100'}
                                   hasError={errors.includes('manufactured')}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Engine (hp)</label>
                            <Input name={'engine_hp'} value={data.engine_hp}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>0-100 (km/h)</label>
                            <Input name={'to100_time'} value={data.to100_time}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Top speed (km/h)</label>
                            <Input name={'top_speed'} value={data.top_speed}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Number of seats*</label>
                            <Input name={'seats'} value={data.seats} errorText={'from 1 to 21'}
                                   hasError={errors.includes('seats')}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Body color*</label>
                            <CSelect values={colors as optionType} name={'body_color_id'}
                                     value={data.body_color_id ? data.body_color_id : "-"}
                                     placeHolder={'Choose body color'} onChange={changeHandler}
                                     hasError={errors.includes('body_color_id')} renderOptionValue={(i: any) => {
                                return <><span className={st.roundColor}
                                               style={{backgroundColor: i.rgb_code}}></span> {i.name}</>;

                            }}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Interior color*</label>
                            <CSelect values={colors as optionType} name={'interior_color_id'}
                                     value={data.interior_color_id ? data.interior_color_id : "-"}
                                     placeHolder={'Choose interior color'} onChange={changeHandler}
                                     hasError={errors.includes('interior_color_id')} renderOptionValue={(i: any) => {
                                return <><span className={st.roundColor}
                                               style={{backgroundColor: i.rgb_code}}></span> {i.name}</>;
                            }}/>

                        </div>
                        <div className={st.formVertical}>
                            <label>Youtube video URL</label>
                            <Input name={'video_link'} value={data.video_link}
                                   onChange={changeHandler}/>
                        </div>
                        <div className={st.formVertical}>
                            <label>Cp art number</label>
                            <Input name={'cp_art_number'} value={data.cp_art_number}
                                   onChange={changeHandler}/>
                        </div>

                    </div>
                    <div className={st.row}>
                        <div className={st.formVertical}>
                            <label>Partner link</label>
                            <Input name={'partner_link'} value={data.partner_link}
                                   onChange={changeHandler}/>
                        </div>
                    </div>
                    <div className={st.row + ' ' + st.rowHeader}>
                        Choose feature
                    </div>
                    <div className={st.row}>
                        <div className={st.cube4}>
                            {extraOptions && Object.entries(extraOptions).map(([key, value]: any) => {
                                    return (<div className={st.formHorizontal} key={value.id}>
                                        <Checkbox
                                            name={value.code}
                                            value={value.code}
                                            isChecked={(data.checkedExtraOption as any).includes(value.code)}
                                            onChange={changeExtraOptionsHandler}/>
                                        {value.name}
                                    </div>);
                                }
                            )}
                        </div>
                    </div>
                    <div className={st.row + ' ' + st.cube2 + ' ' + st.formHorizontal}>
                        <div className={st.rowHeader}>
                            Prices
                        </div>
                        <div className={st.cube2 + ' ' + st.formHorizontal}>
                            <label>Daily mileage (km) </label>
                            <Input name={'daily_mileage'}
                                   value={(data.daily_mileage) ? data.daily_mileage : (userMillage) ? userMillage : ""}
                                   onChange={changeHandler}/></div>
                    </div>
                    <div className={st.row}>
                        <div className={st.cube2}>
                            <div className={st.formVertical}>
                                <span className={st.grayText}>From Day</span>
                            </div>
                            <div className={st.formVertical}>
                                <span className={st.grayText}>Daily Price ({localStorage.getItem('def_currency_symbol')?localStorage.getItem('def_currency_symbol'):counterPartySymbol})</span>
                            </div>

                            {
                                prices.length > 0 && prices.map((item, index) => {
                                    return (
                                        <React.Fragment key={index + 'priceline'}>
                                            <Input name={'days'} value={item.days}
                                                   isReadOnly={true}
                                                   hasError={hasPriceError(item, 'days')}
                                                   onChange={(e) => changePrice(e.target, index)}/>
                                            <Input name={'value'} value={item.value}
                                                   hasError={hasPriceError(item, 'value')}
                                                   onChange={(e) => changePrice(e.target, index)}/>
                                        </React.Fragment>
                                    );
                                })
                            }
                            {
                                prices.length < 1 ? (
                                    'Choose counterparty'
                                ) : ''


                            }

                        </div>
                    </div>
                    <div className={st.row + ' ' + st.rowHeader}>
                        Extras
                    </div>
                    <div className={st.row}>
                        <div className={st.cube3}>
                            {Object.entries(priceOptions).map(([key, obj]: any) => {
                                    return (
                                        <PriceOptionsLine key={obj.code + data.id} obj={obj}
                                                          value={(extras as any)[obj.code]}
                                                          onChangeLine={onPriceOptionsLineChange}/>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className={st.row + ' ' + st.rowHeader}>
                        Description
                    </div>
                    <div className={st.row}>
                        <Textarea name={'description'} value={data.description}
                                  onChange={changeHandler}/>
                    </div>
                    <div className={st.row + ' ' + st.rowHeader}>
                        Is published
                        <RadioButton value={'true'} isChecked={(data.published + '' === 'true')}
                                     onClick={() => {
                                         setData((draft) => {
                                             draft.published = 'true'
                                         })
                                     }}/> Yes
                        <RadioButton value={'false'} isChecked={(data.published + '' === 'false')}
                                     onClick={() => {
                                         setData((draft) => {
                                             draft.published = 'false'
                                         })
                                     }}/> No
                    </div>
                    <div className={st.row}>
                        <Gallery imgs={data.imgs} onChooseFiles={setImages} mainImg={data.mainImg}
                                 onSort={onSortOfGallery}
                                 checkImages={setCheckedImages}/>
                    </div>
                    <div className={st.row + ' ' + st.flexRight}>
                        {checkedImages.length >= 1 ? (
                            <Button color={"danger"} onClick={removeImages}>Delete Images</Button>
                        ) : (<></>)}
                        {checkedImages.length === 1 ? (
                            <Button color={"warring"} onClick={setMainPAge}>Set Main Page</Button>
                        ) : (<></>)}
                        {/*<Button color={"white"}>Cancel</Button>*/}
                        <Button color={"blue"} onClick={sendDataButton}>Save</Button>
                    </div>

                </div>
            </div>
        </>)

    );
}

export default memo(AutoForm);