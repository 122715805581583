import React, {ChangeEvent, Suspense, useEffect, useState} from "react";
import {IOrder} from "../../types/Order";
import axios from "axios";
import {API} from "../../const/API";
import {NavLink} from "react-router-dom";
import icoEdit from "../../imgs/Icon-Edit.svg";
import st from "../../styles/template.module.scss";
import DataTable from "../../components/DataTable/DataTable";
import {IOrderState} from "../../types/OrderState";
import icoSearch from "../../imgs/search-normal.svg";
import {IContact} from "../../types/Contact";
import Checkbox from "../../components/Checkbox/Checkbox";
import CSelect from "../../components/Cselect/CSelect";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

type Props = {
    id: number
}
export default function ContactsDetails(props: Props) {
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [orderStates, setOrderStates] = useState(Array<IOrderState>);
    const [contacts, setContacts] = useState(Array<IContact>);
    const [isOnlyActive, setIsOnlyActive] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState("-");


    useEffect(() => {
        axios.get(API + '/contacts/').then((response) => {
            if (response?.data) {
                setContacts(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            console.log(e);
        });
        if (props?.id > 0) {

            axios.get(API + '/OrderStates/').then((response) => {
                if (response?.data) {
                    setOrderStates(response.data);
                    // console.log(response.data)
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                axios.get(API + '/orders/' + props.id + '/').then((response) => {
                    if (response?.data) {
                        setOrders(response.data);
                        // console.log(response.data)
                    }
                }).catch((e) => {
                    console.log(e);
                });
            });
        }
    }, [props.id])
    return (
        <>
            {/*<div className={st.body_panel_heading_control}>*/}
            {/*    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>*/}
            {/*        <Checkbox name={'activeOnly'} value={1} isChecked={isOnlyActive}*/}
            {/*                  onChange={() => setIsOnlyActive(!isOnlyActive)}/> - active only*/}
            {/*    </div>*/}
            {/*    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>*/}
            {/*        <CSelect value={selectedStatus} onChange={(obj: ChangeEvent<HTMLInputElement>) => {*/}
            {/*            setSelectedStatus(obj?.target?.value);*/}
            {/*        }}  name={'state'} isSelect2={true} values={orderStates as any} placeHolder={'State'}/>*/}
            {/*    </div>*/}
            {/*    /!*<Input name={'search'} value={searchText} onChange={(evt) => setSearchText(evt.target.value)}*!/*/}
            {/*    /!*       placeholder={'type contact'}/>*!/*/}
            {/*    /!*<NavLink to={'/contactForm/'}><Button color={"blue"} type={"plus"}>Add*!/*/}
            {/*    /!*    contact</Button></NavLink>*!/*/}
            {/*</div>*/}

            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'Contact',
                        id: 'contact_id_',
                        render: (row: any) => {
                            let r = 'unknown';
                            contacts.forEach((i) => {
                                if (i.id == row.contact_id) {
                                    r = i.first_name ?? i.first_name + " " + i.middle_name ?? i.middle_name + " " + i.last_name ?? i.last_name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'From',
                        id: 'ts_from_',
                        field: "ts_from"
                    },
                    {
                        name: 'Order created',
                        id: 'ts_created_',
                        field: "ts_created"
                    },

                    {
                        name: 'Duration',
                        id: 'duration_',
                        field: "duration"
                    },
                    {
                        name: 'Status',
                        id: 'status_',
                        render: (row: any) => {
                            let r = 'unknown';
                            orderStates.forEach((i) => {
                                if (i.id == row.order_state_id) {
                                    r = i.name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'Control',
                        id: 'control_',
                        render: (row: any) => {
                            if (row.order_state_id == 1) {
                                return <NavLink to={'/orderForm/' + row.id + '/' + row.contact_id}><img
                                    src={icoEdit}
                                    alt={'edit'}/></NavLink>; ////orderForm/0/5
                            } else {
                                return <NavLink to={'/orderShow/' + row.id + '/'}><img src={icoSearch}
                                                                                       alt={'show'}/></NavLink>;

                            }
                        }
                    }
                ]}

                rowsPerPage={50}
                data={orders}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/></>);

}