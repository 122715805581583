import st from "./Button.module.scss";
import searchLogo from './../Icos/ico-search.svg'
import homeLogo from './../Icos/ico-home.svg';
import addIco from "./../Icos/ico-add.svg";
import React from "react";

type ButtonProps = {
    size?: 'btn' | 'btn-md';
    color?: 'white' | 'blue' | 'light-blue' | 'danger' | 'warring' | 'green';
    type?: 'blank' | 'search' | 'home' | 'plus';
    children?: string;
    className?: string;
    onClick?: any;
    customClass?: string;
}


function Button({
                    size = 'btn',
                    color = 'white',
                    children,
                    type = 'blank',
                    className = '',
                    onClick,
                    customClass = ''

                }: ButtonProps) {
    className = className + ' ' + st[size] + (typeof st[color] != "undefined" ? ' ' + st[color] : '') + ((customClass !== '') ?' '+ customClass : '');
    let img = null;
    switch (type) {
        case 'search':
            img = searchLogo;
            break
        case 'home':
            img = homeLogo;
            break
        case 'plus':
            img = addIco;
            break
        default:
            img = null;
            break

    }
    return (
        <div className={className} onClick={onClick}>
            {(img !== null) ? <img src={img} alt={'img'}/> : ''}{children}
        </div>
    )
}

export default Button;
