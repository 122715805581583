import st from './Checkbox.module.scss';

type CheckboxType = {
    name?: string;
    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
    isChecked?: boolean;
    isDisabled?: boolean;
    onClick?: any;}

function Checkbox({
                      placeholder = '',
                      value = '',
                      onChange,
                      onClick,
                      name = '',
                      isChecked = false,
                      isDisabled = false
                  }: CheckboxType) {
    return (
        <input type={'checkbox'} disabled={isDisabled} className={st.input} placeholder={placeholder} name={name}
               checked={isChecked} value={value}
               onChange={onChange} onClick={onClick}/>
    );
}

export default Checkbox