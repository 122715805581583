import st from "../styles/template.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";

import axios from "axios";
import {API} from "../const/API";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../components/Modal/Modal";

import {IOrder} from "../types/Order";
import {IOrderState} from "../types/OrderState";
import {ICity} from "../types/City";
import {IPartnersRequest} from "../types/PartnersRequest";
import Button from "../components/Button/Button";
import Checkbox from "../components/Checkbox/Checkbox";

import Input from "../components/Input/Input";
import DtFormatter from "../helpers/DtFormatter";


const WorkStatusID = 1;
let searchTimeOut: any;
const DFields = ['order_state_id', 'close_reason_id',
    'close_comments', 'pickup_location', 'drop_location', 'driver_age', 'driver_exp',
    'driver_nationality_id', 'price_from', 'price_to', 'price_currency_id', 'engine_from', 'engine_to', 'categories_list',
    'classes_list', 'brands_list', 'models_list', 'transmissions_list', 'drive_types_list', 'fuel_types_list',
    'seats_list', 'body_colors_list', 'interior_colors_list', 'partners_list', 'uid_partner_request',
    'ts_partner_request', 'ref_vehicle_id', 'client_vehicles_list', 'resp_user_code', 'ts_created'];
const initialSeats = [{name: "1", code: "1", id: "1"}, {name: "2", code: "2", id: "2"},
    {name: "3", code: "3", id: "3"}, {name: "4", code: "4", id: "4"}, {name: "5", code: "5", id: "5"},
    {name: "6", code: "6", id: "6"}, {name: "7", code: "7", id: "7"}, {name: "8", code: "8", id: "8"},
    {name: "9", code: "9", id: "9"}, {name: "10", code: "10", id: "10"}, {name: "11", code: "11", id: "11"},
    {name: "12", code: "12", id: "12"}];

export default function MyOrderShow() {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    let {contactId, orderId} = useParams();
    const [cities, setCities] = useState([]);
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState("");
    const [order, setOrder] = useState({contact_id: 0} as IOrder)
    const [errorMessage, setErrorMessage] = useState("");
    const [currencies, setCurrencies] = useState([]);
    const [partners, setPartners] = useState([]);
    const [partnersRequest, setPartnersRequest] = useState({} as IPartnersRequest);
    const [partnerState, setPartnerState] = useState({} as IOrderState);
    const [isChangedCars, setIsChangedCars] = useState(false);
    const modal = (c: any) => {
        setMessage(c);
        setShowModal(true);
    }
    const sendUpdateRequest = async (update: boolean) => {
        let resp = await axios.post(API + '/UpdatePartnerRequest/' + (update ? "1/" : ""), {
            obj: JSON.stringify(partnersRequest),
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (update) {
                navigate(0);
            }
            axios.get(API + '/PartnersRequest/' + orderId + '/').then((res) => {
                if (res?.data) {
                    if (res.data.data.details) {
                        res.data.data.details = JSON.parse(res.data.data.details);
                    }
                    setPartnersRequest(res.data.data[0]);
                }
            }).catch((e) => {
                setError(true);
            }).finally(() => {
                axios.get(API + '/PartnersStates/').then((responseState) => {
                    if (responseState?.data.data) {
                        responseState.data.data.map((os: any) => {
                            if (os.id == order.order_partner_state_id) {
                                setPartnerState(os);
                            }
                        });
                    }
                }).catch((e) => {
                    setError(true);
                })
                setIsLoaded(true);
            });

        }).catch((e) => {
            modal(e.response.data);
        });
        console.log(resp);
    }


    useEffect(() => {
        if (!orderId || orderId === "0") {
            setOrder((draft) => {
                if (contactId) {
                    draft.contact_id = parseInt(contactId);
                    draft.order_state_id = 1;
                    return JSON.parse(JSON.stringify(draft));
                }
            })
        } else {
            axios.get(API + '/Order/' + orderId + '/').then((responseOrder) => {
                if (responseOrder?.data) {
                    setOrder(responseOrder.data);
                    axios.get(API + '/PartnersRequest/' + orderId + '/').then((res) => {
                        if (res?.data) {
                            if (res.data.data.details) {
                                res.data.data.details = JSON.parse(res.data.data.details);
                            }
                            setPartnersRequest(res.data.data[0]);
                        }
                    }).catch((e) => {
                        setError(true);
                    }).finally(() => {
                        axios.get(API + '/PartnersStates/').then((responseState) => {
                            if (responseState?.data.data) {
                                responseState.data.data.map((os: any) => {
                                    if (os.id == responseOrder.data.order_partner_state_id) {
                                        setPartnerState(os);
                                    }
                                });
                            }
                        }).catch((e) => {
                            setError(true);
                        })
                        setIsLoaded(true);
                    });
                }
            }).catch((e) => {
                setError(true);
            });

        }
        //валюты
        axios.get(API + '/currencies/').then((response) => {
            if (response?.data) {
                setCurrencies(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });

        //города
        axios.get(API + '/cities/').then((response) => {
            if (response?.data) {
                setCities(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
    }, []);
    return (
        !isLoaded ? <>Loading</> :
            <>
                <div className={st.body_panel_heading}>
                    <div className={'f24 ' + st.cube2}>
                        <div className={st.flexLeft}>Order</div>
                    </div>
                    <div className={st.body_panel_heading_control}>
                    </div>
                </div>
                <div className={st.body_panel_body}>
                    <div className={st.cube2}>
                        <div>
                            <div className={st.cube2}>
                                <div className={st.row + ' ' + st.row_compact}>
                                    <div className={st.showBlock}>
                                        <div>Create time:</div>
                                        <div>{DtFormatter(order?.ts_partner_request)}</div>
                                    </div>
                                </div>
                                <div className={st.row + ' ' + st.row_compact}>
                                    <div className={st.showBlock}>
                                        <div>Expire date:</div>
                                        <div>{DtFormatter(order?.partner_ts_deadline)}</div>
                                    </div>
                                </div>
                                <div className={st.row + ' ' + st.row_compact}>
                                    <div className={st.showBlock}>
                                        <div>State:</div>
                                        <div>{partnerState.name}
                                        </div>
                                    </div>
                                </div>
                                <div className={st.row + ' ' + st.row_compact}>
                                    <div className={st.showBlock}>
                                        <div>Start:</div>
                                        <div>{DtFormatter(order?.ts_from)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={st.cube3}>
                                {order?.duration ?
                                    <div className={st.row + ' ' + st.row_compact}>
                                        <div className={st.showBlock}>
                                            <div>Duration:</div>
                                            <div>{order?.duration}</div>

                                        </div>
                                    </div> : <></>}
                                {order?.city_id ?
                                    <div className={st.row + ' ' + st.row_compact}>
                                        <div className={st.showBlock}>
                                            <div>City:</div>
                                            <div>{cities.map((city: ICity) => {
                                                if (city.id == order.city_id) {
                                                    return city.name;
                                                }
                                            })}</div>

                                        </div>
                                    </div>
                                    : <></>}
                                {partnersRequest?.max_price_formatted ?
                                    <div className={st.row + ' ' + st.row_compact}>
                                        <div className={st.showBlock}>
                                            <div>Max price:</div>
                                            <div>{partnersRequest.max_price_formatted}</div>

                                        </div>
                                    </div>
                                    : <></>}
                            </div>
                        </div>
                        <div>

                            <div className={st.row + ' ' + st.row_compact}>
                                <div className={st.showBlock}>
                                    <div className={st.flex + ' ' + st.flexCol}>
                                        <div className={st.flexRight + ' f14'} style={{marginBottom: '15px'}}>
                                        </div>
                                        {partnerState.code == 'active' ? <>
                                            <h3>Editable Vehicle List</h3>
                                            <table className={st.tableShow + ' ' + st.f12}>
                                                <tbody>
                                                <tr key={'part_' + partnersRequest.id} className={st.bgGreen}>
                                                    <td colSpan={6}>
                                                        {partners.map((p: any) => {
                                                            return (p.id === partnersRequest.counterparty_id ? p.name : "")
                                                        })}&nbsp;
                                                        {currencies.map((c: any) => {
                                                            if (c.id === partnersRequest.currency_id) {
                                                                return "(" + c.symbol + ")";
                                                            }
                                                        })}
                                                    </td>

                                                </tr>
                                                <tr className={st.bgGray} key={'grayPart_' + partnersRequest.id}>
                                                    <td>#</td>
                                                    <td>Available</td>
                                                    <td>Car Name</td>
                                                    <td>System Price/day</td>
                                                    <td>Actual Price/day</td>
                                                    <td>Extra Cost</td>
                                                </tr>

                                                {partnersRequest?.details && partnersRequest.details.map((car: any, idxCar: any) => {
                                                    return <tr key={'cars_' + car.id + partnersRequest.id}>
                                                        <td>{car.id}</td>
                                                        <td><Checkbox key={'car_' + car.id} name={'car_' + car.id}
                                                                      isChecked={car.is_available}
                                                                      onChange={() => {
                                                                          let isReady = false;
                                                                          setPartnersRequest((draft) => {
                                                                              if (isReady == false) {
                                                                                  isReady = true;
                                                                                  draft.details[idxCar].is_available = !draft.details[idxCar].is_available;
                                                                                  return JSON.parse(JSON.stringify(draft));
                                                                              } else {
                                                                                  return draft;
                                                                              }
                                                                          })
                                                                          setIsChangedCars(true);

                                                                      }}/></td>
                                                        <td>
                                                            <a href={"https://rent.cars/cars/" + car.vehicle_url}
                                                               target={"_blank"}>{car.vehicle_name}</a>
                                                        </td>
                                                        <td style={{textAlign: 'right', maxWidth: '70px'}}>
                                                            {car?.init_price}</td>
                                                        <td style={{textAlign: 'right', maxWidth: '70px'}}>
                                                            <Input
                                                                onChange={(e) => {
                                                                    setPartnersRequest((draft) => {
                                                                        draft.details[idxCar].conf_price = e.target.value;
                                                                        return JSON.parse(JSON.stringify(draft));
                                                                    })
                                                                    setIsChangedCars(true);
                                                                }}
                                                                name={'conf_price_' + idxCar} value={car?.conf_price}
                                                                compact={true}/></td>
                                                        <td style={{textAlign: 'right', maxWidth: '70px'}}>
                                                            <Input
                                                                onChange={(e) => {
                                                                    setPartnersRequest((draft) => {
                                                                        draft.details[idxCar].conf_extra = e.target.value;
                                                                        return JSON.parse(JSON.stringify(draft));
                                                                    })
                                                                    setIsChangedCars(true);
                                                                }}
                                                                name={'conf_extra_' + idxCar} value={car?.conf_extra}
                                                                compact={true}/></td>
                                                    </tr>;
                                                })}

                                                </tbody>
                                            </table>
                                        </> : <>
                                            <h3>ReadOnly Vehicle List</h3>
                                            <table className={st.tableShow + ' ' + st.f12}
                                                   style={!order.is_alive ? {opacity: '0.6'} : undefined}>
                                                <tbody>
                                                <tr className={(partnersRequest.status_code === 'depleted' ? st.bgLightRed :
                                                    (partnersRequest.status_code === 'done' ? st.bgGreen :
                                                        undefined))}
                                                    key={'part_' + partnersRequest.id}>
                                                    <td colSpan={6}>
                                                        {partners.map((p: any) => {
                                                            return (p.id === partnersRequest.counterparty_id ? p.name : "")
                                                        })}&nbsp;
                                                        {currencies.map((c: any) => {
                                                            if (c.id === partnersRequest.currency_id) {
                                                                return "(" + c.symbol + ")";
                                                            }
                                                        })}
                                                    </td>

                                                </tr>
                                                <tr className={st.bgGray} key={'grayPart_' + partnersRequest.id}>
                                                    <td>#</td>
                                                    <td>Available</td>
                                                    <td>Car Name</td>
                                                    <td>System Price/day</td>
                                                    <td>Actual Price/day</td>
                                                    <td>Extra Cost</td>
                                                </tr>

                                                {partnersRequest?.details && partnersRequest.details.map((car: any, idxCar: any) => {
                                                    return <tr key={'cars_' + car.id + partnersRequest.id}>
                                                        <td>{idxCar + 1}</td>
                                                        <td></td>
                                                        <td>
                                                            <a href={"https://rent.cars/cars/" + car.vehicle_url}
                                                               target={"_blank"}>{car.vehicle_name}</a>
                                                        </td>
                                                        <td style={{textAlign: 'right'}}>{car?.init_price_formatted}</td>
                                                        <td style={{textAlign: 'right'}}>{car.conf_price?.toLocaleString()}</td>
                                                        <td style={{textAlign: 'right'}}>{car.conf_extra?.toLocaleString()}</td>
                                                    </tr>;
                                                })}

                                                </tbody>
                                            </table>
                                        </>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.row + ' ' + st.flexRight + ' ' + st.stickyBottom}>
                        {partnersRequest?.status_code == 'active' ?
                            <>{isChangedCars ?
                                <Button type={"blank"} color={"green"} onClick={() => {
                                    sendUpdateRequest(false);
                                    setIsChangedCars(false)
                                }}>Save</Button> : <></>}
                                <Button type={"blank"} color={"blue"} onClick={() => {
                                    sendUpdateRequest(true);
                                }
                                }>Save & Confirm</Button></>
                            : ""}
                        <Button onClick={() => navigate('/MyOrders')} type={"blank"} color={"warring"}>Close</Button>

                        <Modal isShowed={error} onClose={setError} withCloseBtn={true}>
                            {errorMessage}</Modal>

                        <Modal isShowed={showModal} onClose={() => {
                            setShowModal(false)
                        }} withCloseBtn={true}>
                            {message}</Modal>
                    </div>

                </div>
            </>);
}