import st from "./../styles/LoginForm.module.scss";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import {API} from "../const/API";


function LoginForm() {
    document.title = 'Log in - Rent Cars';
    const nav = useNavigate();
    const Auth = () => {
        axios.post(API + '/login/', {
            email: user.login,
            password: user.password
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (response.data?.data?.role) {
                localStorage.setItem("user", response.data.data.role);
                const backLink = sessionStorage.getItem('backLink');
                if (backLink) {
                    window.location.replace(backLink);
                } else {
                    nav('/');
                }
            }
        }).catch((e) => {
            setError(true);
        });
    }
    const [user, setUser] = useState({login: '', password: ''});
    const [userError, setError] = useState(false);
    return (
        <div className={st.main}>

            <div className={st.form}>
                <div className={st.header}>Sign in</div>
                <div className={st.inputBlock}>
                    <label>E-mail</label>
                    <Input hasError={userError} value={user.login} onChange={(e) => {
                        setUser({...user, login: e.target.value});
                        setError(false);
                    }}/>

                </div>
                <div className={st.inputBlock}>
                    <label>Password</label>
                    <Input type={'password'} hasError={userError} value={user.password} onChange={(e) => {
                        setUser({...user, password: e.target.value});
                        setError(false);
                    }}/>
                </div>

                <Button color={(user.login.length === 0 || user.password.length === 0) ? 'light-blue' : 'blue'}
                        size={'btn-md'} customClass={'w100'} onClick={Auth}>Continue</Button>
            </div>
        </div>
    );
}

export default LoginForm;