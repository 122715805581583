import React, {ChangeEvent, useEffect, useState} from "react";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {IOrder} from "../types/Order";
import DataTable from "../components/DataTable/DataTable";
import {useNavigate} from "react-router-dom";
import {IOrderState} from "../types/OrderState";
import icoSearch from '../imgs/search-normal.svg';
import {IContact} from "../types/Contact";
import CSelect from "../components/Cselect/CSelect";

import Checkbox from "../components/Checkbox/Checkbox";
import {ICity} from "../types/City";
import Input from "../components/Input/Input";
import DtFormatter from "../helpers/DtFormatter";

export default function Orders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [orderStates, setOrderStates] = useState(Array<IOrderState>);
    const [contacts, setContacts] = useState(Array<IContact>);
    const [isOnlyActive, setIsOnlyActive] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState("-");
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("-");
    const [selectedDate, setSelectedDate] = useState("");

    const [error, setError] = useState(false);
    useEffect(() => {
        axios.get(API + '/cities/').then((response) => {
            if (response?.data) {
                setCities(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/contacts/').then((response) => {
            if (response?.data) {
                setContacts(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/OrderStates/').then((response) => {
            if (response?.data) {
                setOrderStates(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        }).finally(() => {
            axios.get(API + '/orders/').then((response) => {
                if (response?.data) {
                    setOrders(response.data);
                    // console.log(response.data)
                }
            }).catch((e) => {
                setError(true);
            });
        })
    }, [])


    useEffect(() => {
        axios.post(API + '/orders/', {
            isOnlyActive: isOnlyActive,
            cityId: selectedCity,
            status: selectedStatus,
            date: selectedDate
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setOrders(response.data);
        }).catch((e) => {
            setError(true);
        });
    }, [isOnlyActive, selectedCity, selectedStatus, selectedDate])


    return (<>
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Orders</div>
                <div className={st.body_panel_heading_control}>
                    <div className={st.gap8} style={{width: 'max-content', minWidth: 180 + "px", display: 'flex'}}>
                        <Checkbox name={'activeOnly'} value={1} isChecked={isOnlyActive}
                                  onChange={() => setIsOnlyActive(!isOnlyActive)}/> - active only
                    </div>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedStatus} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedStatus(obj?.target?.value);
                        }} name={'orderState'} isSelect2={true} values={orderStates as any} placeHolder={'State'}/>
                    </div>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedCity} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedCity(obj?.target?.value);
                        }} name={'orderState'} isSelect2={true} values={cities as any} placeHolder={'City'}/>
                    </div>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <Input type={"date"} value={selectedDate} onChange={(obj) => {
                            setSelectedDate(obj.target.value)
                        }} name={'date'} placeholder={'Date'}/>
                    </div>
                    {/*<Input name={'search'} value={searchText} onChange={(evt) => setSearchText(evt.target.value)}*/}
                    {/*       placeholder={'type contact'}/>*/}
                    {/*<NavLink to={'/contactForm/'}><Button color={"blue"} type={"plus"}>Add*/}
                    {/*    contact</Button></NavLink>*/}
                </div>
            </div>
            <div className={st.body_panel_body}>

            </div>
            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'Create Moment',
                        id: 'create_moment_',
                        render: (i: any) => {
                            return DtFormatter(i.ts_created);
                        }
                    },
                    {
                        name: 'Contact',
                        id: 'contact_id_',
                        render: (row: any) => {
                            let r = 'unknown';
                            contacts.forEach((i) => {
                                if (i.id == row.contact_id) {
                                    r = (i.first_name ? i.first_name : "") + " " + (i.middle_name ? i.middle_name : "") + " " + (i.last_name ? i.last_name : "") + ' [' + i.nationality_code + ']';
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'State',
                        id: 'status_',
                        render: (row: any) => {
                            let r = 'unknown';
                            orderStates.forEach((i) => {
                                if (i.id == row.order_state_id) {
                                    r = i.name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'City',
                        id: 'city_id_',
                        render: (i: any) => {
                            let city = "-";
                            cities.map((c: ICity) => {
                                if (c.id == i.city_id) {
                                    city = c.name;
                                }
                            })
                            return city;
                        }
                    },
                    {
                        name: 'From',
                        id: 'ts_from_',
                        render: (i: any) => {
                            return DtFormatter(i.ts_from);
                        }
                    },
                    {
                        name: 'Duration',
                        id: 'duration_',
                        field: "duration"
                    },
                    {
                        name: 'Partner Request',
                        id: 'partner_request_',
                        render: (i: any) => {
                            if (i.order_state_id !== 2) {
                                return "-";
                            } else {
                                return <><span
                                    className={st.quad + ' ' + (i?.partner_request_list.total_cars_available > 0 ? st.quad_blue : st.quad_red)}>{i?.partner_request_list.total_cars_available}
                                    </span>
                                    <span
                                        className={st.quad + ' ' + st.quad_gray}>{i?.partner_request_list.total_cars_requested}</span></>;
                            }
                        }
                    },
                    {
                        name: 'Direct Link',
                        id: 'direct_link_',
                        render: (row: any) => {
                            if (row.order_state_id !== 1) {
                                return <img src={icoSearch}
                                            alt={'show'} style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                try {
                                                    navigator.clipboard.writeText(window.location.origin + '/orderShow/' + row.uid_partner_request);
                                                    alert('link copied');
                                                } catch (err) {
                                                    alert('can not copy')
                                                }
                                            }}/>; ///orderShow/81d41d4b-58d9-4847-b9f2-092a1aa8b8c5/ //row.uid_partner_request
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        name: 'Close Reason',
                        id: 'close_reason_',
                        render: (i: any) => {
                            if (i.close_comments == null) {
                                return "-";
                            } else {
                                return i.close_comments;
                            }
                        }
                    },
                    // {
                    //     name: 'Control',
                    //     id: 'control_',
                    //     render: (row: any) => {
                    //         if (row.order_state_id == 1) {
                    //             return <NavLink to={'/orderForm/' + row.id + '/' + row.contact_id}><img src={icoEdit}
                    //                                                                                     alt={'edit'}/></NavLink>; ////orderForm/0/5
                    //         } else {
                    //             return <NavLink to={'/orderShow/' + row.uid_partner_request + '/'}><img src={icoSearch}
                    //                                                                                     alt={'show'}/></NavLink>;
                    //             ;
                    //         }
                    //     }
                    // }
                ]}
                onRowDoubleClick={(row) => {
                    if (row.order_state_id == 1) {
                        navigate('/orderForm/' + row.id + '/' + row.contact_id);

                    } else {
                        navigate('/orderShow/' + row.uid_partner_request + '/');

                    }
                }}
                rowStyle={(row) => {
                    return row.is_deadline ? st.bgLightRed : undefined
                }}
                rowsPerPage={50}
                data={orders}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/>
        </>
    )

}